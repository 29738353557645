import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import { DateTime } from 'luxon';
import EndpointConnectButton from '../VMConnectButton/EndpointConnect';

function EndpointRow(props) {
  const { endpoint } = props;
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {endpoint.facilityName}
      </TableCell>
      <TableCell>{endpoint.name}</TableCell>
      <TableCell>{endpoint.pcName}</TableCell>
      <TableCell>{endpoint.location}</TableCell>
      <TableCell>{endpoint.locationPhoneNumber}</TableCell>
      <TableCell>
        {endpoint.lastSessionTimeInUtc &&
          DateTime.fromISO(endpoint.lastSessionTimeInUtc).toFormat('LLL dd, yyyy HH:mm')}
      </TableCell>
      <TableCell>
        <EndpointConnectButton row={endpoint} />
      </TableCell>
    </TableRow>
  );
}

export default EndpointRow;
