import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
}));

const HeaderTab = (props) => {
  const { activeTab, handleTabChange } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="tabs"
      >
        <Tab label="Endpoints" />
        <Tab label="My Acute Requests" />
        <Tab label="All Acute Requests" />
        <Tab label="Today's Ambulatory Appointments" />
      </Tabs>
    </div>
  );
};

export default HeaderTab;
