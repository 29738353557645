import axios from 'axios';
import { SIZE } from '../../constants';

export const redirectSettings = {
  // encryptionHeaders: {
  //   'AccessId': 'f782f44a-81bd-4277-947c-df0a4d9f418e',
  //   'AccessKey': 'KBOTC0KLZNN3DSFF4N2HS8D5OEQ4CVWQ3U28BS900HOOWC1L3HNH60L2Q6JYMFR9MG3JQ7NCX20BKP9C',
  //   'Content-Type': 'application/json',
  //   // 'Access-Control-Allow-Origin': '*',
  //   // 'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE',
  //   // 'Access-Control-Allow-Headers': 'Authorization, Lang'
  // },
  // encryptionUrl: "https://stage-aws.veemed.com/platform/api/params/encrypt",//https://cors-anywhere.herokuapp.com/
  // encryptionUrl: "https://dev-aws.veemed.com/platform/api/params/encrypt",//https://cors-anywhere.herokuapp.com/
  // teleSessionRedirectBaseUrl: "https://dev-aws.veemed.com/veedoc-launch/#/connectto",
  // key: "de59b311-200d-4c1e-8ccd-282b1e2d50d3"
  // encryptionHeaders: {
  //   // 'AccessId': '3693d1d5-a2b2-49f8-b467-c54b155d5120',
  //   // 'AccessKey': '6VZ4M8V1IIEUS43-6S6QBAXT5JN8R42O1CEZSBC68FA3H2NZK6AUXS0NM92EC0I76R8AV32N2QMIMVR',
  //   'AccessId': process.env.REACT_APP_ACCESS_ID,
  //   'AccessKey': process.env.REACT_APP_ACCESS_KEY,
  //   'Content-Type': 'application/json',
  // },
  // encryptionUrl: "https://dev-aws.veemed.com/platform/api/params/encrypt",//https://cors-anywhere.herokuapp.com/
  // encryptionUrl: "https://onvfh63hrk.execute-api.us-east-2.amazonaws.com/Dev/openapi/api/1.0/token/endpoint", //"https://dev-aws.veemed.com/platform/api/params/encrypt",//https://cors-anywhere.herokuapp.com/
  // locationLookupUrl: "https://onvfh63hrk.execute-api.us-east-2.amazonaws.com/Dev/openapi/api/1.0/location/", //"https://dev-aws.veemed.com/platform/api/endpoint/unique/location/ICU-100",
  // teleSessionRedirectBaseUrl: "https://dev-aws.veemed.com/veedoc-launch/#/connectto",
  //key: "T0WJHQKGLO7TA68VBQFD62Q1REH7EP7QZBI2PO4H8W1AHS0TDJO1KLAO3ZOOJJPAEY81BP6NMH5TKG3I"
};

export const encrypt = async (tenant, encryptionData) => {
  const response = await axios.post(process.env.REACT_APP_ENCRYPTION_URL, encryptionData, {
    headers: {
      'Content-Type': 'application/json',
      tenant: tenant,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const encryptForRequest = async (tenant, encryptionData) => {
  const response = await axios.post(process.env.REACT_APP_TOKEN_FOR_REQUEST, encryptionData, {
    headers: {
      'Content-Type': 'application/json',
      tenant: tenant,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const encryptForAppointment = async (tenant, encryptionData) => {
  const response = await axios.post(process.env.REACT_APP_TOKEN_FOR_APPOINTMENT, encryptionData, {
    headers: {
      'Content-Type': 'application/json',
      tenant: tenant,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const lookupLocation = async (tenant, uniqueLocation) => {
  const response = await axios.get(
    process.env.REACT_APP_LOCATION_LOOKUP_URL +
      uniqueLocation +
      '?currTime=' +
      new Date().toISOString(),
    {
      headers: {
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response;
};

export const getRequests = async (tenant) => {
  const response = await axios.post(
    process.env.REACT_APP_REQUEST_URL,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response.data;
};

export const listSpeciality = async (tenant) => {
  const response = await axios.get(process.env.REACT_APP_LIST_SPECIALITY, {
    headers: {
      tenant: tenant,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  });
  return response;
};

export const listFacility = async (tenant) => {
  const response = await axios.get(process.env.REACT_APP_LIST_FACILITY, {
    headers: {
      tenant: tenant,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  });
  return response;
};

export const defer = async (tenant, orderId, providerId, comments) => {
  const response = await axios.post(
    process.env.REACT_APP_DEFER,
    {
      orderId,
      providerId,
      comments,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response.data;
};

/***
 * reject request
 */
export const reject = async (tenant, orderId, providerId) => {
  const response = await axios.post(
    process.env.REACT_APP_REJECT,
    {
      orderId,
      providerId,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response.data;
};

/***
 * reject appointment
 */
export const rejectAppt = async (tenant, apptId) => {
  const response = await axios.delete(process.env.REACT_APP_REJECT_APPOINTMENT + '/' + apptId, {
    headers: {
      'Content-Type': 'application/json',
      tenant: tenant,
      'x-api-key': process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const listTodayAppointments = async (tenant, npi) => {
  const response = await axios.get(
    process.env.REACT_APP_LIST_APPOINTMENT_TODAY +
      '/' +
      npi +
      '?page=1&size=100&currTime=' +
      new Date().toISOString(),
    {
      headers: {
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response;
};

export const createQuickAppoingment = async (tenant, appointment) => {
  const appendix =
    '?smsToPatient=' +
    process.env.REACT_APP_APPOINTMENT_SMS_TO_PATIENT +
    '&emailToPatient=' +
    process.env.REACT_APP_APPOINTMENT_EMAIL_TO_PATIENT;
  const response = await axios.post(
    process.env.REACT_APP_CREATE_QUICK_APPOINTMENT + appendix,
    appointment,
    {
      headers: {
        'Content-Type': 'application/json',
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response.data;
};

export const createScheduledAppoingment = async (tenant, appointment) => {
  const appendix =
    '?smsToPatient=' +
    process.env.REACT_APP_APPOINTMENT_SMS_TO_PATIENT +
    '&emailToPatient=' +
    process.env.REACT_APP_APPOINTMENT_EMAIL_TO_PATIENT;
  const response = await axios.post(
    process.env.REACT_APP_CREATE_SCHEDULED_APPOINTMENT + appendix,
    appointment,
    {
      headers: {
        'Content-Type': 'application/json',
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response.data;
};

export const listAllEndpoints = async (
  tenant,
  npi,
  page,
  search,
  connectionStatus,
  sortBy,
  facility,
) => {
  const response = await axios.get(
    `${
      process.env.REACT_APP_ENDPOINTS_URL
    }?providerId=${npi}&facilityId=${facility}&filter=${search}&connectionStatus=${connectionStatus}&sortBy=${sortBy}&&page=${page}&size=${SIZE}&currTime=${new Date().toISOString()}`,
    {
      headers: {
        tenant: tenant,
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    },
  );
  return response;
};
