import React from 'react';
import { Table, TableCell, TableHead, TableRow, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EndpointRow from './EndpointRow';
import { connect } from 'react-redux';
import { VMPagination } from '../VMPagination';
import { useState } from 'react';
import { LOAD_ALL_ENDPOINTS, LOAD_ALL_ENDPOINTS_HARD } from '../../containers/Home/constants';
import { useEffect } from 'react';
import Loading from '../Loading/Loading';

const useStyles = makeStyles(() => ({
  header: {
    color: '#03ADD0',
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  header: {
    fontWeight: '600',
  },
  actionColumn: {
    minWidth: '150px',
  },
});

function EmptyRow() {
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell colSpan={7} align="center">
          {'No endpoint found. Please reset filter.'}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const EndpointsTable = (props) => {
  const {
    facility,
    endpointSortBy,
    allEndpoints,
    endpointStatus,
    endpointSearch,
    loadAllEndpoints,
    loadAllEndpointsHard,
    // page,
    // handlePageChange,
  } = props;
  const endpoints = allEndpoints?.data;
  const loading = allEndpoints?.loading;
  const pages = allEndpoints?.pageCount === 0 ? 1 : allEndpoints?.pageCount;
  const [page, setPage] = useState(1);
  const classes = useStyles();

  const params = {
    page,
    search: endpointSearch,
    connectionStatus: endpointStatus,
    sortBy: endpointSortBy,
    facility: facility === '' ? 0 : facility,
  };

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  useEffect(() => {
    loadAllEndpointsHard(params);
    const interval = setInterval(() => {
      loadAllEndpoints(params);
    }, 10000);
    return () => clearInterval(interval);
  }, [
    loadAllEndpoints,
    loadAllEndpointsHard,
    page,
    endpointSearch,
    endpointStatus,
    endpointSortBy,
    facility,
  ]);

  useEffect(() => {
    setPage(1);
  }, [endpointSearch, endpointStatus, facility]);

  if (loading && !endpoints) {
    return <Loading />;
  }

  return (
    <Table aria-label="table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.header}>Facility</TableCell>
          <TableCell className={classes.header}>Endpoint</TableCell>
          <TableCell className={classes.header}>PC Name</TableCell>
          <TableCell className={classes.header}>Location</TableCell>
          <TableCell className={classes.header}>Contact Number</TableCell>
          <TableCell className={classes.header}>Last Session</TableCell>
          <TableCell className={classes.header}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {endpoints && endpoints.length === 0 && <EmptyRow />}
        {endpoints &&
          endpoints.map((endpoint) => {
            return <EndpointRow key={endpoint.id} endpoint={endpoint} />;
          })}

        <TableRow>
          <TableCell colSpan={7} align="right">
            <VMPagination pages={pages} page={page} handlePageChange={handlePageChange} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state) => {
  return {
    allEndpoints: state.home.allEndpoints,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllEndpoints: (params) => {
      dispatch({ type: LOAD_ALL_ENDPOINTS, params });
    },
    loadAllEndpointsHard: (params) => {
      dispatch({ type: LOAD_ALL_ENDPOINTS_HARD, params });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointsTable);
