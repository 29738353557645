import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'cerner-smart-embeddable-lib';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { createTheme } from '@material-ui/core/styles';

import configureStore from './store/index';

import App from './containers/App';
import { ThemeProvider } from '@material-ui/core';

// import 'semantic-ui-css/semantic.min.css';
import './styles.css';
// import { enableES5 } from 'immer';
const store = configureStore({});
// enableES5();

const theme = createTheme({
  palette: {
    primary: {
      main: '#03ADD0',
    },
    secondary: {
      main: '#4caf50',
    },
    warning: {
      main: '#ffb74d',
    },
  },
  typography: {
    fontFamily: `'Segoe UI', Tahoma, Geneva, Verdana, sans-serif`,
    button: {
      textTransform: 'none',
    },
  },
  // overrides: {
  //   MuiTableRow: {
  //     root: {
  //       whiteSpace: 'nowrap',
  //     },
  //   },
  // },
});

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root'),
);
