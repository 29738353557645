import React from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

const EndpointFilter = (props) => {
  const {
    facility,
    facilities,
    endpointStatus,
    endpointSortBy,
    handleFilterChange,
    handleEndpointSearch,
    handleEndpointSortByChange,
  } = props;
  const useFilterStyles = makeStyles((theme) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    formControl: {
      margin: theme.spacing(1.5),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const filterClasses = useFilterStyles();

  return (
    <div className={filterClasses.wrapper}>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Facility</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={facility}
          name="Facility"
          onChange={handleFilterChange}
          label="Facility"
        >
          <MenuItem value="0">All</MenuItem>
          {facilities &&
            facilities.data.map((f) => {
              return (
                <MenuItem key={f.id} value={f.id}>
                  {f.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          value={endpointStatus}
          name="EndpointStatus"
          onChange={handleFilterChange}
          label="EndpointStatus"
        >
          <MenuItem value={'online'}>Online</MenuItem>
          <MenuItem value={'offline'}>Offline</MenuItem>
          <MenuItem value={'busy'}>Busy</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Sort By</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={endpointSortBy}
          name="SortBy"
          onChange={handleEndpointSortByChange}
          label="SortBy"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'name'}>Name</MenuItem>
          <MenuItem value={'Location'}>Location</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={filterClasses.formControl}>
        <TextField
          variant="outlined"
          label="Search"
          type="text"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleEndpointSearch(e);
            }
          }}
          // value={reason}
        />
      </FormControl>
    </div>
  );
};

export default EndpointFilter;
