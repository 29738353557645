/**
 * sagas
 */

import { all, put, call, takeEvery, select } from 'redux-saga/effects';
import connect from '../../services/FhirClient';

import {
  LOAD_PATIENT_INFO,
  LOAD_PRACTITIONER,
  CREATE_OP_APPOINTMENT,
  CONNECT_INPATIENT,
  LOAD_ENDPOINT,
  LOAD_REQUEST,
  CONNECT_FROM_REQUEST,
  DEFER_A_CALL,
  REJECT_A_CALL,
  LOAD_FILTERS,
  LOAD_ENCRYPTED_CODE,
  LOAD_OUTPATIENT_APPOINTMENT_LIST,
  CREATE_OUTPATIENT_APPOINTMENT,
  CONNECT_FROM_APPOINTMENT,
  REJECT_APPOINTMENT,
  LOAD_ENCOUNTER_INFO,
  LOAD_ALL_ENDPOINTS,
  LOAD_ALL_ENDPOINTS_HARD,
  LOAD_ENDPOINT_ENCRYPTED_CODE,
} from './constants';
import {
  loadPatientInfoActionError,
  loadPatientInfoActionSuccess,
  createAppointmentSuccess,
  loadEncounterInfoActionSuccess,
  loadPractitionerInfoActionSuccess,
  loadPractitionerInfoActionError,
  loadEndpoingStatusActionSuccess,
  loadRequestsInfoActionSuccess,
  loadRequestsInfoActionError,
  loadEncryptedCodeActionSuccess,
  loadSpecialityListActionSuccess,
  loadFacilityListActionSuccess,
  rejectACallFailed,
  rejectACallSuccess,
  deferACallSuccess,
  deferACallFailed,
  loadFiltersError,
  loadOutpatientAppointmentListActionSuccess,
  loadOutpatientAppointmentListActionError,
  createOutpatientAppointmentError,
  createOutpatientAppointmentSuccess,
  rejectAppointmentSuccess,
  rejectAppointmentError,
  loadEncounterInfoActionError,
  loadEndpointStatusActionError,
  loadAllEndpointsSuccess,
  loadAllEndpointsError,
} from './actions';
import {
  encrypt,
  lookupLocation,
  getRequests,
  listSpeciality,
  listFacility,
  defer,
  reject,
  encryptForRequest,
  listTodayAppointments,
  createQuickAppoingment,
  createScheduledAppoingment,
  encryptForAppointment,
  rejectAppt,
  listAllEndpoints,
} from './vmapi';
import { getNpi, getTenant } from './selectors';

const wrapper = (client, query) => {
  return client.request(query, { pageLimit: 2, flat: true });
};

// const refresh = async (client) => {
//   const params = {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded"
//     },
//     body: `grant_type=refresh_token&refresh_token=${client.state.tokenResponse.refresh_token}`,
//     method: "POST"
//   };

//   const res = await fetch (client.state.tokenUri, params);
//   const result = await res.json();
//   console.log(result);
//   return result;
// }

function* loadPatientInfo() {
  try {
    const client = yield call(connect);
    const patient = yield call(client.patient.read);
    yield put(loadPatientInfoActionSuccess(patient));
  } catch (e) {
    yield put(loadPatientInfoActionError(e));
  }
}

function* loadPractitionerInfo() {
  try {
    const client = yield call(connect);
    const practitioner = yield call(
      wrapper,
      client,
      `Practitioner/${client.state.tokenResponse.user}`,
    );
    yield put(loadPractitionerInfoActionSuccess(practitioner));
  } catch (error) {
    yield put(loadPractitionerInfoActionError(error));
  }
}

function* loadEncounterInfo() {
  try {
    const client = yield call(connect);
    const encounter = yield call(client.encounter.read);
    yield put(loadEncounterInfoActionSuccess(encounter));
  } catch (e) {
    yield put(loadEncounterInfoActionError(e));
  }
}

function* loadEndpoint() {
  try {
    const tenant = yield select(getTenant);
    const encounter = yield select(getEncounter);
    var endpoint = null;
    // const endpoint = yield call(lookupLocation, tenant, encounter.location[0].location.reference.split('/')[1]);
    if (encounter) {
      endpoint = yield call(
        lookupLocation,
        tenant,
        encounter.location[0].location.reference.split('/')[1],
      );
      yield put(loadEndpoingStatusActionSuccess(endpoint));
    }
  } catch (e) {
    yield put(loadEndpointStatusActionError(e));
  }
}

const getPatient = (state) => state.home.patient;
const getProvider = (state) => state.home.practitioner;
const getEncounter = (state) => state.home.encounter;

function* loadEncryptedCode() {
  try {
    const encryptedCode = yield getEncryptedCode();
    yield put(loadEncryptedCodeActionSuccess(encryptedCode));
  } catch (error) {
    //TODO: add load failed action
  }
}

function* getEncryptedCode() {
  const tenant = yield select(getTenant);
  const client = yield call(connect);
  const patient = yield select(getPatient);
  if (!patient) {
    patient = yield call(client.patient.read);
  }
  const practitioner = yield select(getProvider);
  if (!practitioner) {
    practitioner = yield call(wrapper, client, `Practitioner/${client.state.tokenResponse.user}`);
  }
  const encounter = yield select(getEncounter);
  var name = {};
  let mrn = '';
  var npi = '';
  if (practitioner) {
    practitioner.identifier.map((identifier) => {
      if (identifier.type.text === 'National Provider Identifier') {
        npi = identifier.value;
      }
      return 0;
    });
  }
  patient.name.forEach((n) => {
    if (n.use === 'official') {
      name.patientFirstName = n.family;
      name.patientLastName = n.given[0];
    }
  });
  patient.identifier.forEach((identifier) => {
    if (identifier.type && identifier.type.text === 'MRN') {
      mrn = identifier.value;
    }
  });
  // const serial = yield call(lookupLocation, tenant, encounter.location[0].location.reference.split('/')[1]);
  const serial = yield call(
    lookupLocation,
    tenant,
    encounter.location[0].location.reference.split('/')[1],
  );
  const encryptionData = {
    // "serialNumber": "RANCH-01",
    serialNumber: serial.data.serialNumber, //"SN-0011",
    mrn: mrn, // pulling multiple MRN
    patientFirstName: name.patientFirstName,
    patientLastName: name.patientLastName,
    patientDOB: patient.birthDate, //"2015-02-02",
    patientSex: patient.gender, //"Male",
    providerid: npi,
    reason: 'Pain', // where to pull the reason?
  };
  const encryptionCode = yield call(encrypt, tenant, encryptionData);
  return encryptionCode;
}

function* loadEndpointLaunchCode({ row }) {
  try {
    const tenant = yield select(getTenant);
    var npi = yield select(getNpi);
    if (!npi) {
      yield loadPractitionerInfo();
      npi = yield select(getNpi);
    }
    const encryptionData = {
      serialNumber: row.serialNumber,
      providerid: npi,
    };
    const encryptionCode = yield call(encrypt, tenant, encryptionData);
    yield put(loadEncryptedCodeActionSuccess(encryptionCode));
  } catch (error) {
    //TODO: add load failed action
  }
}

/**
 * this is deprecated
 */
function* inpatientConnect() {
  // const client = yield call(connect);
  const patient = yield select(getPatient);
  const practitioner = yield select(getProvider);
  const encounter = yield select(getEncounter);
  const tenant = yield select(getTenant);
  // const location = yield call(wrapper, client, `${encounter.location[0].location.reference}`);
  let name = {};
  let mrn = '';
  let npi = '';
  if (practitioner) {
    practitioner.identifier.map((identifier) => {
      if (identifier.type.text === 'National Provider Identifier') {
        npi = identifier.value;
      }
      return 0;
    });
  }

  patient.name.forEach((n) => {
    if (n.use === 'official') {
      name.patientFirstName = n.family;
      name.patientLastName = n.given[0];
    }
  });
  patient.identifier.forEach((identifier) => {
    if (identifier.type && identifier.type.text === 'MRN') {
      mrn = identifier.value;
    }
  });
  const serial = yield call(
    lookupLocation,
    tenant,
    encounter.location[0].location.reference.split('/')[1],
  );
  const encryptionData = {
    // "serialNumber": "RANCH-01",
    serialNumber: serial.data.serialNumber, //"SN-0011",
    mrn: mrn, // pulling multiple MRN
    ...name, //"patientFirstName": "Danyal", "patientLastName": "Ahmed",
    patientDOB: patient.birthDate, //"2015-02-02",
    patientSex: patient.gender, //"Male",
    providerid: npi,
    reason: 'Pain', // where to pull the reason?
  };
  const encryptionCode = yield call(encrypt, tenant, encryptionData);
  //window.open(`${redirectSettings.teleSessionRedirectBaseUrl}?token=${encryptionCode}`, "_blank");
  window.open(`${process.env.REACT_APP_TELE_REDIRECT_URL}?token=${encryptionCode}`, '_blank'); // enable this one
  // window.open(`${redirectSettings.teleSessionRedirectBaseUrl}?key=${redirectSettings.key}&providerid=${npi}&info=${encryptionCode}`, "_blank");
  //return window.location.assign(`${redirectSettings.teleSessionRedirectBaseUrl}?key=${redirectSettings.key}&providerid=drsmith@veemed.com&info=${encryptionCode}`);
}

function* connectFromRequest({ row }) {
  const tenant = yield select(getTenant);
  let npi = row.provider.npiNumber;
  const encryptionData = {
    orderId: row.id, //"SN-0011",
    providerid: npi,
  };
  const encryptionCode = yield call(encryptForRequest, tenant, encryptionData);
  yield put(loadEncryptedCodeActionSuccess(encryptionCode));
}

function* connectFromAppointment({ row }) {
  const tenant = yield select(getTenant);
  let npi = row.provider.npiNumber;
  const encryptionData = {
    appointmentId: row.id, //"SN-0011",
    providerid: npi,
  };
  const encryptionCode = yield call(encryptForAppointment, tenant, encryptionData);
  yield put(loadEncryptedCodeActionSuccess(encryptionCode));
}

function* loadRequests() {
  try {
    const tenant = yield select(getTenant);
    const requests = yield call(getRequests, tenant);
    yield put(loadRequestsInfoActionSuccess(requests));
  } catch {
    yield put(loadRequestsInfoActionError('something wrong to load request.'));
  }
}

function* loadFilters() {
  try {
    const tenant = yield select(getTenant);
    const specialityList = yield call(listSpeciality, tenant);
    const facilityList = yield call(listFacility, tenant);
    yield put(loadSpecialityListActionSuccess(specialityList));
    yield put(loadFacilityListActionSuccess(facilityList));
  } catch (error) {
    yield put(loadFiltersError(error));
  }
}

/**
 * deprecased
 */
function* createOPAppointment() {
  yield new Promise((resolve) => setTimeout(resolve, 3000));
  yield put(createAppointmentSuccess());
}

function* deferACall({ row, value }) {
  const tenant = yield select(getTenant);
  try {
    const deferRes = yield call(defer, tenant, row.id, row.provider.npiNumber, value);
    yield put(deferACallSuccess(deferRes));
  } catch (error) {
    yield put(deferACallFailed(error));
  }
}

function* rejectACall({ row }) {
  const tenant = yield select(getTenant);
  try {
    const rejectRes = yield call(reject, tenant, row.id, row.provider.npiNumber);
    yield put(rejectACallSuccess(rejectRes));
  } catch (error) {
    yield put(rejectACallFailed(error));
  }
}

function* rejectAppointment({ row }) {
  const tenant = yield select(getTenant);
  try {
    const rejectRes = yield call(rejectAppt, tenant, row.id);
    yield put(rejectAppointmentSuccess(rejectRes));
    yield call(loadOutpatientAppointments);
  } catch (error) {
    yield put(rejectAppointmentError(error));
  }
}

function* loadOutpatientAppointments() {
  try {
    const tenant = yield select(getTenant);
    const npi = yield select(getNpi);
    const appointments = yield call(listTodayAppointments, tenant, npi);
    yield put(loadOutpatientAppointmentListActionSuccess(appointments.data));
  } catch (error) {
    yield put(loadOutpatientAppointmentListActionError(error));
  }
}

function* createOutpatientAppointment({ appointment }) {
  try {
    const tenant = yield select(getTenant);
    if (appointment.appointmentType === 'quick') {
      yield call(createQuickAppoingment, tenant, appointment);
    } else {
      yield call(createScheduledAppoingment, tenant, appointment);
    }
    yield put(createOutpatientAppointmentSuccess(appointment));
  } catch (error) {
    yield put(createOutpatientAppointmentError(error));
  }
}

function* loadAllEndpoints({ params }) {
  try {
    const tenant = yield select(getTenant);
    var npi = yield select(getNpi);
    if (!npi) {
      yield loadPractitionerInfo();
      npi = yield select(getNpi);
    }
    const allEndpoints = yield call(
      listAllEndpoints,
      tenant,
      npi,
      params.page,
      params.search,
      params.connectionStatus,
      params.sortBy,
      params.facility,
    );
    yield put(loadAllEndpointsSuccess(allEndpoints.data));
  } catch (error) {
    yield put(loadAllEndpointsError(error));
  }
}

export default function* root() {
  yield all([
    takeEvery(LOAD_PATIENT_INFO, loadPatientInfo),
    takeEvery(LOAD_PRACTITIONER, loadPractitionerInfo),
    takeEvery(LOAD_ENCOUNTER_INFO, loadEncounterInfo),
    takeEvery(LOAD_ENCRYPTED_CODE, loadEncryptedCode),
    takeEvery(LOAD_ENDPOINT_ENCRYPTED_CODE, loadEndpointLaunchCode),
    takeEvery(CREATE_OP_APPOINTMENT, createOPAppointment),
    takeEvery(CONNECT_INPATIENT, inpatientConnect),
    takeEvery(LOAD_ENDPOINT, loadEndpoint),
    takeEvery(LOAD_REQUEST, loadRequests),
    takeEvery(CONNECT_FROM_REQUEST, connectFromRequest),
    takeEvery(CONNECT_FROM_APPOINTMENT, connectFromAppointment),
    takeEvery(DEFER_A_CALL, deferACall),
    takeEvery(REJECT_A_CALL, rejectACall),
    takeEvery(LOAD_FILTERS, loadFilters),
    takeEvery(LOAD_OUTPATIENT_APPOINTMENT_LIST, loadOutpatientAppointments),
    takeEvery(CREATE_OUTPATIENT_APPOINTMENT, createOutpatientAppointment),
    takeEvery(REJECT_APPOINTMENT, rejectAppointment),
    takeEvery(LOAD_ALL_ENDPOINTS, loadAllEndpoints),
    takeEvery(LOAD_ALL_ENDPOINTS_HARD, loadAllEndpoints),
  ]);
}
