import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
}));

export const VMPagination = (props) => {
  const { page, pages, handlePageChange } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Pagination count={pages} page={page} color="primary" onChange={handlePageChange} />
    </div>
  );
};
