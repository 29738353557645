/**
 * The global state selectors
 */

// import { createSelector } from 'reselect';
// import { initialState } from './reducers';
import _ from 'lodash';

export const getReady = (state) => {
  return _.get(state, 'app.smart.user');
};

export const getPatientId = (state) => {
  return _.get(state, 'app.smart.patient');
};

export const getTenant = (state) => {
  return _.get(state, 'app.smart.tenant');
};

export const getEncounter = (state) => {
  return _.get(state, 'home.encounter');
};

export const getPatient = (state) => {
  return _.get(state, 'home.patient');
};

export const getEndpoint = (state) => {
  return _.get(state, 'home.endpoint');
};

export const getPractitioner = (state) => {
  return _.get(state, 'home.practitioner');
};

export const getNpi = (state) => {
  const practitioner = _.get(state, 'home.practitioner');
  let npi = '';
  if (practitioner) {
    practitioner.identifier.forEach((identifier) => {
      if (identifier.type.text === 'National Provider Identifier') {
        npi = identifier.value;
      }
    });
  }
  return npi;
  // return '20564897';
};

export default getReady;
