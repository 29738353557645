/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce, { enableES5 } from 'immer';

import {
  LOAD_PATIENT_INFO_ERROR,
  LOAD_PATIENT_INFO_SUCCESS,
  LOAD_APPOINTMENT_INFO_SUCCESS,
  LOAD_APPOINTMENT_INFO_ERROR,
  CREATE_OP_APPOINTMENT_SUCCESS,
  LOAD_ENCOUNTER_INFO_SUCCESS,
  LOAD_ENCOUNTER_INFO_ERROR,
  LOAD_PRACTITIONER_SUCCESS,
  LOAD_ENDPOINT_SUCCESS,
  LOAD_REQUEST_SUCCESS,
  LOAD_REQUEST_ERROR,
  LOAD_ENCRYPTED_CODE_SUCCESS,
  LOAD_SPECIALITY_LIST_SUCCESS,
  LOAD_FACILITY_LIST_SUCCESS,
  REJECT_A_CALL_ERROR,
  REJECT_A_CALL_SUCCESS,
  DEFER_A_CALL_ERROR,
  DEFER_A_CALL_SUCCESS,
  LOAD_OUTPATIENT_APPOINTMENT_LIST_SUCCESS,
  LOAD_OUTPATIENT_APPOINTMENT_LIST_ERROR,
  CREATE_OUTPATIENT_APPOINTMENT_ERROR,
  CREATE_OUTPATIENT_APPOINTMENT_SUCCESS,
  LOAD_ENDPOINT_ERROR,
  LOAD_ALL_ENDPOINTS_SUCCESS,
  LOAD_ALL_ENDPOINTS_ERROR,
  LOAD_ALL_ENDPOINTS,
  LOAD_ALL_ENDPOINTS_HARD,
} from './constants';

// The initial state of the App
export const initialState = {
  container: 'Home',
};

const homeReducer = (state = initialState, action) => {
  enableES5();
  return produce(state, (draft) => {
    switch (action.type) {
      case LOAD_PATIENT_INFO_SUCCESS:
        draft.patient = action.payload;
        break;
      case LOAD_PATIENT_INFO_ERROR:
        draft.patient = {
          error: 'Patient load failed',
        };
        break;
      case LOAD_APPOINTMENT_INFO_SUCCESS:
        draft.appointments = action.payload;
        break;
      case LOAD_APPOINTMENT_INFO_ERROR:
        draft.appointments = {
          error: 'Appointment information laod failed',
        };
        break;
      case CREATE_OP_APPOINTMENT_SUCCESS:
        draft.opappointment = action.payload;
        break;
      case LOAD_ENCOUNTER_INFO_SUCCESS:
        draft.encounter = action.payload;
        break;
      case LOAD_ENCOUNTER_INFO_ERROR:
        draft.encounter = {
          error: 'Something went wrong. Patient encounter load failed.',
        };
        break;
      case LOAD_PRACTITIONER_SUCCESS:
        draft.practitioner = action.payload;
        break;
      case LOAD_ENDPOINT_SUCCESS:
        draft.endpoint = action.payload.data;
        break;
      case LOAD_ENDPOINT_ERROR:
        draft.endpoint = action.payload;
        break;
      case LOAD_REQUEST_SUCCESS:
        draft.requests = action.payload.data;
        break;
      case LOAD_REQUEST_ERROR:
        draft.requests = action.payload;
        break;
      case LOAD_ENCRYPTED_CODE_SUCCESS:
        draft.encryptedCode = action.payload;
        break;
      case LOAD_SPECIALITY_LIST_SUCCESS:
        draft.specialties = action.payload;
        break;
      case LOAD_FACILITY_LIST_SUCCESS:
        draft.facilities = action.payload;
        break;
      case REJECT_A_CALL_SUCCESS:
        draft.rejectCallRes = action.payload;
        break;
      case REJECT_A_CALL_ERROR:
        draft.rejectCallRes = action.payload;
        break;
      case DEFER_A_CALL_SUCCESS:
        draft.deferCallRes = action.payload;
        break;
      case DEFER_A_CALL_ERROR:
        draft.deferCallRes = action.payload;
        break;
      case LOAD_OUTPATIENT_APPOINTMENT_LIST_SUCCESS:
        draft.outpaitentAppointments = action.payload;
        break;
      case LOAD_OUTPATIENT_APPOINTMENT_LIST_ERROR:
        draft.outpaitentAppointments = action.payload;
        break;
      case CREATE_OUTPATIENT_APPOINTMENT_SUCCESS:
        draft.createOutpatientAppointmentRes = action.payload;
        break;
      case CREATE_OUTPATIENT_APPOINTMENT_ERROR:
        draft.createOutpatientAppointmentRes = action.payload;
        break;
      case LOAD_ALL_ENDPOINTS:
        draft.allEndpoints = { ...state?.allEndpoints, loading: true };
        break;
      case LOAD_ALL_ENDPOINTS_HARD:
        draft.allEndpoints = { loading: true };
        break;
      case LOAD_ALL_ENDPOINTS_SUCCESS:
        draft.allEndpoints = action.payload;
        break;
      case LOAD_ALL_ENDPOINTS_ERROR:
        draft.allEndpoints = action.payload;
        break;
      default:
        break;
    }
  });
};

export default homeReducer;
