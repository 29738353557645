/**
 * @module Actions/App
 * @desc App Actions
 */

import {
  LOAD_PATIENT_INFO,
  LOAD_PATIENT_INFO_SUCCESS,
  LOAD_PATIENT_INFO_ERROR,
  LOAD_APPOINTMENT_INFO_SUCCESS,
  LOAD_APPOINTMENT_INFO_ERROR,
  CREATE_OP_APPOINTMENT_SUCCESS,
  LOAD_ENCOUNTER_INFO_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  LOAD_PRACTITIONER_SUCCESS,
  LOAD_ENDPOINT,
  LOAD_ENDPOINT_SUCCESS,
  LOAD_REQUEST_SUCCESS,
  LOAD_REQUEST_ERROR,
  LOAD_ENCRYPTED_CODE_SUCCESS,
  LOAD_SPECIALITY_LIST_SUCCESS,
  LOAD_FACILITY_LIST_SUCCESS,
  REJECT_A_CALL_ERROR,
  REJECT_A_CALL_SUCCESS,
  DEFER_A_CALL_SUCCESS,
  DEFER_A_CALL_ERROR,
  LOAD_FILTERS_ERROR,
  LOAD_OUTPATIENT_APPOINTMENT_LIST_SUCCESS,
  LOAD_OUTPATIENT_APPOINTMENT_LIST_ERROR,
  CREATE_OUTPATIENT_APPOINTMENT_ERROR,
  CREATE_OUTPATIENT_APPOINTMENT_SUCCESS,
  REJECT_APPOINTMENT_SUCCESS,
  REJECT_APPOINTMENT_ERROR,
  LOAD_ENCOUNTER_INFO_ERROR,
  LOAD_ENDPOINT_ERROR,
  LOAD_PRACTITIONER_ERROR,
  LOAD_ALL_ENDPOINTS_SUCCESS,
  LOAD_ALL_ENDPOINTS_ERROR,
} from './constants';

export const loadPatientInfoAction = () => ({
  type: LOAD_PATIENT_INFO,
});

export const loadPatientInfoActionSuccess = (patient) => ({
  type: LOAD_PATIENT_INFO_SUCCESS,
  payload: patient,
});

export const loadPatientInfoActionError = (e) => ({
  type: LOAD_PATIENT_INFO_ERROR,
  payload: e,
});

/**
 * THIS IS DEPRECATED
 */
export const loadAppointmentInfoActionSuccess = (appointments) => ({
  type: LOAD_APPOINTMENT_INFO_SUCCESS,
  payload: appointments,
});

/**
 * THIS IS DEPRECATED
 */
export const loadAppointmentInfoActionError = (e) => ({
  type: LOAD_APPOINTMENT_INFO_ERROR,
  payload: e,
});

export const createAppointmentSuccess = () => ({
  type: CREATE_OP_APPOINTMENT_SUCCESS,
  payload: true,
});

export const loadEncounterInfoActionSuccess = (encounter) => ({
  type: LOAD_ENCOUNTER_INFO_SUCCESS,
  payload: encounter,
});

export const loadEncounterInfoActionError = (e) => ({
  type: LOAD_ENCOUNTER_INFO_ERROR,
  payload: e,
});

export const refreshTokenSuccess = (payload) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: payload,
});

export const loadPractitionerInfoActionSuccess = (practitioner) => ({
  type: LOAD_PRACTITIONER_SUCCESS,
  payload: practitioner,
});

export const loadPractitionerInfoActionError = (e) => ({
  type: LOAD_PRACTITIONER_ERROR,
  payload: e,
});

export const loadEndpointStatusAction = () => ({
  type: LOAD_ENDPOINT,
});

export const loadEndpoingStatusActionSuccess = (endpoint) => ({
  type: LOAD_ENDPOINT_SUCCESS,
  payload: endpoint,
});

export const loadEndpointStatusActionError = (e) => ({
  type: LOAD_ENDPOINT_ERROR,
  payload: { error: true },
});

export const loadRequestsInfoActionSuccess = (requests) => ({
  type: LOAD_REQUEST_SUCCESS,
  payload: requests,
});

export const loadRequestsInfoActionError = (err) => ({
  type: LOAD_REQUEST_ERROR,
  payload: err,
});

export const loadEncryptedCodeActionSuccess = (encryptedCode) => ({
  type: LOAD_ENCRYPTED_CODE_SUCCESS,
  payload: encryptedCode,
});

export const loadSpecialityListActionSuccess = (specialityList) => ({
  type: LOAD_SPECIALITY_LIST_SUCCESS,
  payload: specialityList,
});

export const loadFacilityListActionSuccess = (facilityList) => ({
  type: LOAD_FACILITY_LIST_SUCCESS,
  payload: facilityList,
});

export const loadFiltersError = (error) => ({
  type: LOAD_FILTERS_ERROR,
  payload: 'something went wrong when load filters',
});

export const rejectACallFailed = (error) => ({
  type: REJECT_A_CALL_ERROR,
  payload: 'something went wrong when reject this call',
});

export const rejectACallSuccess = (res) => ({
  type: REJECT_A_CALL_SUCCESS,
  payload: res,
});

export const deferACallSuccess = (res) => ({
  type: DEFER_A_CALL_SUCCESS,
  payload: res,
});

export const deferACallFailed = (error) => ({
  type: DEFER_A_CALL_ERROR,
  payload: 'something went wrong when defer this call',
});

export const loadOutpatientAppointmentListActionSuccess = (res) => ({
  type: LOAD_OUTPATIENT_APPOINTMENT_LIST_SUCCESS,
  payload: res,
});

export const loadOutpatientAppointmentListActionError = (error) => ({
  type: LOAD_OUTPATIENT_APPOINTMENT_LIST_ERROR,
  payload: error,
  // payload: 'something went wrong when loading outpatient appointment',
});

export const createOutpatientAppointmentSuccess = (res) => ({
  type: CREATE_OUTPATIENT_APPOINTMENT_SUCCESS,
  payload: [new Date().toISOString(), 'success'],
});

export const createOutpatientAppointmentError = (error) => ({
  type: CREATE_OUTPATIENT_APPOINTMENT_ERROR,
  payload: [new Date().toISOString()].concat(error.response.data),
});

export const rejectAppointmentSuccess = (res) => ({
  type: REJECT_APPOINTMENT_SUCCESS,
  payload: res,
});

export const rejectAppointmentError = (error) => ({
  type: REJECT_APPOINTMENT_ERROR,
  payload: error,
});

export const loadAllEndpointsSuccess = (res) => ({
  type: LOAD_ALL_ENDPOINTS_SUCCESS,
  payload: res,
});

export const loadAllEndpointsError = (error) => ({
  type: LOAD_ALL_ENDPOINTS_ERROR,
  payload: error,
});
