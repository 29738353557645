import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FiberManualRecord } from '@material-ui/icons';
import { LOAD_ENDPOINT_ENCRYPTED_CODE } from '../../containers/Home/constants';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  busy: {
    color: theme.palette.warning.main,
    '&:disabled': {
      color: theme.palette.warning.main,
    },
  },
  offline: {
    color: theme.palette.error.main,
    '&:disabled': {
      color: theme.palette.error.main,
    },
  },
}));

function EndpointConnectButton(props) {
  const classes = useStyles();
  const { row, connectFromEndpoint, code } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    connectFromEndpoint(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function createMarkup(code) {
    const link = `${process.env.REACT_APP_TELE_REDIRECT_URL}?token=${code}`;
    console.log(link);
    return {
      __html: `<a class="MuiButton-label" style="text-decoration:none; margin-right: 20px; margin-top: -1px;  color: #03ADD0" href="javascript:APPLINK(100,'${link}','')">Yes</a>`,
    };
  }

  const createButton = () => {
    switch (row.kartStatus) {
      case 'online':
        return (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FiberManualRecord color="secondary" />}
            onClick={handleClickOpen}
          >
            Connect
          </Button>
        );
      case 'offline':
        return (
          <Button
            color="primary"
            className={classes.offline}
            startIcon={<FiberManualRecord className={classes.offline} />}
            disabled
          >
            Offline
          </Button>
        );
      case 'busy':
        return (
          <Button
            color="primary"
            className={classes.busy}
            startIcon={<FiberManualRecord className={classes.busy} />}
            disabled
          >
            Busy
          </Button>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    // let interval = null;
    if (open) {
      connectFromEndpoint(row);
      // interval = setInterval(() => {
      //   connectFromEndpoint(row);
      // }, 50000);
    }
    // else {
    // clearInterval(interval);
    // }
    // return () => clearInterval(interval);
  }, [open, connectFromEndpoint, row]);

  useEffect(() => {
    setOpen(false);
  }, [row.kartStatus]);

  return (
    <>
      {createButton()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you ready to connect to ${row.name}?`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <div dangerouslySetInnerHTML={createMarkup(code)} />
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    code: state.home.encryptedCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectFromEndpoint: (row) => {
      dispatch({ type: LOAD_ENDPOINT_ENCRYPTED_CODE, row });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndpointConnectButton);
